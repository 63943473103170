.App {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-image: linear-gradient(rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.95)), url('./assets/brickwallpaper1.jpg');
  background-size: 100%;
}

.spotlight {
  pointer-events: none;
  position: absolute;
  inset: 1px;
  opacity: 0;
  transition: opacity 300ms;
}
 
h1 {
  font-size: 4rem;
  color: #99efff;
  text-shadow: 
  0.1vw 0vw 0.25vw #99efff, 0.2vw 0vw 0.25vw #99efff, 0.4vw 0vw 0.25vw #99efff,
  0.1vw 0vw 0vw #00b4d4, 0.2vw 0vw 0vw #00b4d4, 0.4vw 0vw 0vw #00b4d4,
  0.1vw 0vw 2vw #00b4d4, 0.2vw 0vw 2vw #00b4d4, 0.4vw 0vw 2vw #00b4d4,
  0.1vw 0vw 1vw #006072, 0.2vw 0vw 1vw #006072, 0.4vw 0vw 1vw #006072,
  0.1vw 0vw 5vw #006072, 0.2vw 0vw 5vw #006072, 0.4vw 0vw 5vw #006072;
}

.title-wrapper {
  width: 90%;
}

h2 {
  font-size: 3rem;
  margin-bottom: 5rem;
  color: #99efff;
  text-shadow: 
  0.1vw 0vw 0.25vw #99efff, 0.2vw 0vw 0.25vw #99efff, 0.1vw 0vw 0.25vw #99efff,
  0.1vw 0vw 0vw #00b4d4, 0.2vw 0vw 0vw #00b4d4, 0.1vw 0vw 0.25vw #00b4d4,
  0.1vw 0vw 2vw #00b4d4, 0.2vw 0vw 2vw #00b4d4, 0.4vw 0vw 2vw #00b4d4,
  0.1vw 0vw 1vw #006072, 0.2vw 0vw 1vw #006072, 0.4vw 0vw 2vw #006072,
  0.1vw 0vw 5vw #006072, 0.2vw 0vw 5vw #006072, 0.4vw 0vw 5vw #006072;
}

.flicker {
  -webkit-animation: flickerAnimation 5s infinite;
   -moz-animation: flickerAnimation 5s infinite;
   -o-animation: flickerAnimation 5s infinite;
    animation: flickerAnimation 5s infinite;
}


@keyframes flickerAnimation {
  0%   { opacity:1; }
  20%   { opacity:.8; }
  40%   { opacity:1; }
  42% { opacity:.8; }
  44%  { opacity:1; }
  60%  { opacity:1; }
  74%  { opacity:1; }
  80%  { opacity:.6; }
  81%  { opacity:.8; }
  100% { opacity:1; }
}
@-o-keyframes flickerAnimation{
  0%   { opacity:1; }
  20%   { opacity:.8; }
  40%   { opacity:1; }
  42% { opacity:.8; }
  44%  { opacity:1; }
  60%  { opacity:1; }
  74%  { opacity:1; }
  80%  { opacity:.6; }
  81%  { opacity:.8; }
  100% { opacity:1; }
}
@-moz-keyframes flickerAnimation{
  0%   { opacity:1; }
  20%   { opacity:.8; }
  40%   { opacity:1; }
  42% { opacity:.8; }
  44%  { opacity:1; }
  60%  { opacity:1; }
  74%  { opacity:1; }
  80%  { opacity:.6; }
  81%  { opacity:.8; }
  100% { opacity:1; }
}
@-webkit-keyframes flickerAnimation{
  0%   { opacity:1; }
  20%   { opacity:.8; }
  40%   { opacity:1; }
  42% { opacity:.8; }
  44%  { opacity:1; }
  60%  { opacity:1; }
  74%  { opacity:1; }
  80%  { opacity:.6; }
  81%  { opacity:.8; }
  100% { opacity:1; }
}

.scrollbtn {
  font-family: "Chakra Petch", serif;
  /* font-size: 1.5rem; */
  width: 3rem;
  height: 3rem;
  color: #ebff99;
  background-color: #080808;
  border: 2px solid #ebff99;
  border-radius: 50%;
  transition: background-color 0.5s ease,
  color 0.5s ease;
  transform: scale(1);
  animation: pulse 2s infinite;
}

.scrollbtn a {
  color: #ebff99;
}

.scrollbtn:hover {
  color: #080808;
  background-color: #ebff99;
  cursor: pointer;
}

.scrollbtn a:hover {
  color: #080808;
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(235, 255, 153, 0.7);
  }

  70% {
      transform: scale(1);
      box-shadow: 0 0 0 15px rgba(235, 255, 153, 0);
  }

  100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(235, 255, 153, 0);
  }
}

.arrow {
  font-size: 1.5rem;
  vertical-align: middle;
}


h3 {
  font-size: 1.2em;
  text-align: left;
}

header {
  position: sticky;
  top: 0;
}

.navbar {
  
  overflow: hidden;
  display: flex;
  justify-content: end;
  align-items: center;
  background-color: #000;
  list-style-type: none;
  width: 100%;
  margin: 0;
  border-bottom: 5px solid #00b4d4;
  transition: border-color 0.5s ease;
}

.navbar a {
  text-decoration: none;
  color: #00b4d4;
  /* font-size: 2rem; */
  font-weight: 700;
  margin-top: 1rem;
  margin-bottom: 1rem;
  margin-right: 2rem;
  pointer-events: auto;
  font-size: 1.4rem;
  transition: text-shadow 0.5s ease,
  color 0.5s ease;
}


.navbar a:hover {
  color: #fff;
  text-shadow: 0 0 20px #00b4d4, 0 0 20px #00b4d4, 0 0 20px #00b4d4, 0 0 20px #00b4d4, 0 0 20px #00b4d4, 0 0 20px #00b4d4, 0 0 20px #00b4d4;
  cursor: pointer;
}

.navbar:has(a:hover) {
  border-color:#99efff;
}

.nav-btn {
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
  color: #fff;
  visibility: hidden;
  opacity: 0;
  font-size: 1.8rem;
  margin: 0 1rem;
}

.page {
  width: 100%;
  height: 100vh;
  padding-top: 4rem;
  display: flex;
  justify-content: center;
}

.page1 {
  width: 100%;
  height: 130vh;
  padding-top: 4rem;
  display: flex;
  justify-content: center;
}

.innerpage-wrapper {
  width: 50%;
  
}

.highlights {
  color: #fff;
  text-shadow: 0 0 10px #00b4d4, 0 0 10px #00b4d4, 0 0 10px #00b4d4, 0 0 10px #00b4d4, 0 0 10px #00b4d4, 0 0 10px #00b4d4, 0 0 10px #00b4d4;
}

li {
  line-height: 2;
  text-align: left;
}

p {
  line-height: 2;
  font-size: 1em;
}

.selfie {
  width: 8rem;
  height: 8rem;
  border: 5px solid #00b4d4;
  border-radius: 50%;
}

.icons {
  width: 2rem;
  height: 2rem;
  margin-right: 1rem;
  padding: 0.5rem;
  color: #00b4d4;
  border: 1px solid #00b4d4;
  border-radius: 50%;
  transition: box-shadow 0.5s ease,
  color 0.5s ease;
}

.icons:hover {
  color: #fff;
  box-shadow: 0 0 10px #00b4d4, 0 0 10px #00b4d4, 0 0 10px #00b4d4, 0 0 10px #00b4d4, 0 0 10px #00b4d4, 0 0 10px #00b4d4, 0 0 10px #00b4d4;

}

@media only screen and (max-width: 1024px) {
  .innerpage-wrapper {
    width: 90%;
  }

  header {
  display: flex;
  align-items: center;
  justify-content: right;
  position: sticky;
  top: 0;
  height: 4rem;
  color: #fff;
}

  header .nav-btn {
    /* position: sticky; */
    
    visibility: visible;
    opacity: 1;

  }

  .nav-btn {
    top: 2rem;
    right: 2rem;
  }


  .navbar {
      position: fixed;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 1.5rem;
      transition: 1s;
      border-bottom: 5px solid #00b4d4;
      /* transition: border-color 0.5s ease; */
      background-color: #080808;
      background-size: cover;
      transform: translateX(100vW);
  }

  .responsive_nav {
      transform: none;
  }

  .navbar .nav-close-btn {
      position: absolute;
      top: 2rem;
      right: 2rem;
  }

  .page1 {
    width: 100%;
    height: 160vh;
    padding-top: 4rem;
    display: flex;
    justify-content: center;
  }

  .navbar a {
    font-size: 1.5rem;
    text-decoration: none;
    color: #00b4d4;
    /* font-size: 2rem; */
    font-weight: 700;
    margin: 1rem auto;
    pointer-events: auto;
    font-size: 1.4rem;
    transition: text-shadow 0.5s ease,
    color 0.5s ease;
  }
}

.footer {
  margin-bottom: 4rem;
}